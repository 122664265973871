@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
:root {
	--primary: #000000;
	--title-color: #000000;
	--effect-color: #ed1d24;
	--secondary: #fff;
	--accent: #ed1d24;

	--hero: 9.31rem;
	--hero-sm: 7.45rem;
	--title-xl: 5.96rem;
	--title-lg: 4.77rem;
	--title: 3.815rem;
	--fs-xxxxl: 3.052rem;
	--fs-xxxl: 2.441rem;
	--fs-xxl: 1.953rem;
	--fs-xl: 1.563rem;
	--fs-lg: 1.25rem;
	--fs-md: 1rem;
}

body {
	margin: 0;
	text-rendering: optimizeLegibility;
	background-color: var(--primary);
	font-family: "Oswald", sans-serif;
	font-optical-sizing: auto;
}
.underline-link-center {
	position: relative;
	color: var(--secondary);
	text-decoration: none;
}
.underline-link-center::after {
	content: "";
	position: absolute;
	height: 4px;
	background-color: var(--secondary);
	width: 0%;
	left: 50%;
	border-radius: 8px;
	bottom: -2px;
	transition: all 0.4s;
}
.underline-link-center:hover {
	cursor: pointer;
}
.underline-link-center:hover::after {
	width: 100%;
	left: 0;
}
.clearfix {
	clear: both;
}
/*navbar*/
nav {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
	background: var(--primary);
	color: var(--secondary);
	font-family: "Roboto Condensed", sans-serif;
	border-bottom: 2px solid var(--accent);
	transition: 0.4s border-bottom ease;
}
nav .container {
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 32px;
}
.navbar-links ul {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
}
.navbar-links li {
	margin-left: 20px;
}
.nav-logo {
	font-size: var(--fs-xxl);
}
.nav-link {
	position: relative;
	font-family: "Roboto Condensed", sans-serif;
	color: var(--secondary);
	text-decoration: none;
	font-size: var(--fs-md);
	font-weight: 500;
	background: none;
	border: none;
}

.nav-link::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0%;
	height: 2px;
	background-color: var(--secondary);
	transition: all 0.3s ease;
	transform: translateX(-50%);
}

.nav-link:hover::before {
	width: 100%;
}
.nav-link:hover {
	cursor: pointer;
}
/*landing*/
.landing {
	width: 100%;
}
/*landing container - movie container*/
#landing {
	background: var(--accent);
	min-height: 960px;
	padding: 80px 40px 40px 40px;
}
#landing .container {
	max-width: 1400px;
	margin: 0 auto;
}
.landing-movie {
	font-family: "Oswald", sans-serif;
	margin: 40px 0;
}
@media screen and (max-width: 2000px) {
	.title-under {
		flex-direction: column;
	}
	.landing-movie-title {
		white-space: normal !important;
		height: initial !important;
	}
}
.landing-movie .page-title {
	color: var(--secondary);
	text-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2); /* Set the text shadow properties */

	font-size: var(--title);
	margin: 0;
	font-weight: 500;
}
.landing-movie-info {
	padding: 24px 8px;
}
.landing-movie-info .movie-title {
	color: var(--title-color);
	font-size: var(--hero);
	font-weight: 500;
	text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Set the text shadow properties */

	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	text-align: left;
}

.landing-movie-info .movie-date {
	font-size: var(--fs-xxl);
	margin: 8px;

	font-weight: 500;
	color: var(--title-color);
}

.movie-countdown-container {
	margin: 16px 0;
}
.movie-countdown-container .movie-countdown {
	color: var(--secondary);
	font-size: var(--title-lg);
	text-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1); /* Set the text shadow properties */
	letter-spacing: 2px;
	font-weight: 400;
}
/* landing container - trailer-container*/
.trailer-container {
	margin: 40px 0;
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.ad-vertical {
	max-width: 160px;
	max-height: 600px;
	background-color: gray;
}
.ad-trailer-vertical {
	max-width: 160px;
	max-height: 600px;
	background-color: gray;
}
.trailer-container {
	font-family: "Roboto Condensed", sans-serif;
}
.trailer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px auto;
}
.trailer-group {
}
.trailer-container .trailer-title {
	font-size: var(--fs-xxxl);
	color: var(--secondary);
	max-width: 100%;
	font-weight: 300;
	letter-spacing: 2.4px;
	margin: 8px auto;
	text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.5);
}
.embedded-trailer {
	width: 640px;
	height: 389px;
	background-color: gray;
	overflow: hidden;
}

.embedded-trailer * {
	width: 100%;
	height: 100%;
}
.ad-horizontal {
	max-width: 728px;
	max-height: 90px;
	margin: 16px 0;
	background-color: gray;
}
/*landing container movie list*/
.viewMovies {
	width: 100%;
	background: var(--primary);
	min-height: 800px;
	padding: 72px 40px 40px 40px;
	box-sizing: border-box;
}
.viewMovies .container {
	max-width: 1400px;
	margin: 32px auto;
	display: flex;
}
.movie-list-group {
	width: 100%;
	color: var(--secondary);
	font-family: "Oswald", sans-serif;
}
.movie-list-group h2 {
	font-size: var(--title);
	font-weight: 400;
	margin: 16px 0;
}
.movie-list-nav {
	width: 100%;
}
.movie-list-nav ul {
	list-style: none;
	display: flex;
	padding: 16px 8px;
	width: 100%;
}
.movie-list-nav li {
	font-size: var(--fs-xl);
	font-weight: 300;
	text-wrap: nowrap;
	margin-right: 24px;
	position: relative;
}
.movie-list-nav li:hover {
	cursor: pointer;
}
.movie-list-nav li::after {
	content: "";
	position: absolute;
	width: 0%;
	bottom: -1px;
	left: 50%;
	height: 2px;
	background-color: var(--secondary);
	transition: all 0.2s;
}
.movie-list-nav li:hover::after {
	width: 100%;
	left: 0%;
}
.movie-list ul {
	list-style: none;
	letter-spacing: 1px;
	font-weight: 300;
	font-family: "Roboto Condensed", sans-serif;
}
.movie-list li {
	font-size: var(--fs-xxl);
	margin: 24px 0;
	position: relative;
	transition: all 0.2s;
	opacity: 1;
	animation: fadeInAndFloat 0.4s ease; /* Apply the animation */
}
.active-list-movie {
	position: relative;
	z-index: 0;
	transition: all 0.4s ease;
}

.active-list-movie::before {
	content: "";
	position: absolute;
	left: -2%;
	width: 104%;
	height: 100%;
	filter: blur(16px);
	opacity: 0.6;
	border-radius: 8px;
	background-color: var(--effect-color);
	z-index: -1;
	transition: 0.4s all ease;
}

.movie-list .released {
	opacity: 0.6;
	animation: fadeInAndFloatReleased 0.4s ease; /* Apply the animation */
}
@keyframes fadeInAndFloat {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fadeInAndFloatReleased {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 0.6;
		transform: translateY(0);
	}
}

.movie-list .movie {
	position: relative;
}
.movie-list .movie::after {
	content: "";
	position: absolute;
	height: 4px;
	background-color: var(--accent);
	width: 0%;
	left: 0;
	border-radius: 8px;
	bottom: -2px;
	transition: all 0.4s;
}
.movie-list .movie:hover {
	cursor: pointer;
}
.movie-list .movie:hover::after {
	width: 100%;
}
.movie-list li .movie-date {
	font-size: var(--fs-xl);
}

/*footer*/
footer {
	background-color: var(--primary);
	margin: 0;
	padding: 0;
	border-top: 2px solid var(--accent);
	min-height: 200px;
	color: var(--secondary);
	padding: 40px;
	box-sizing: border-box;
	font-family: "Roboto Condensed", sans-serif;
	transition: 0.4s border-top ease;
}

.footer .container {
	margin: 0 auto;
	max-width: 1400px;
}
.footer-content {
	display: flex;
	flex-wrap: wrap;
}
.logo-container {
	display: flex;
	align-items: flex-start;
	font-size: var(--fs-xl);
	font-weight: 200;
	letter-spacing: 4px;
	margin-bottom: 16px;
	margin-right: 32px;
}
.logo-container img {
	position: relative;
	bottom: -2px;
	height: calc(var(--fs-xl) + 4px);
	margin-right: 4px;
}
.contact-container {
	margin-right: 32px;
}
.contact-title {
	font-size: var(--fs-lg);
	font-weight: 400;
}
.contact-container ul {
	margin: 8px 0;
	list-style: none;
	font-size: var(--fs-md);
	font-weight: 300;
	padding: 0;
}
.contact-container ul .underline-link-center::after {
	height: 2px;
}
.footer-link-container-title {
	font-size: var(--fs-lg);
	font-weight: 400;
}
.footer-link-container ul {
	margin: 8px 0;
	list-style: none;
	font-size: var(--fs-md);
	font-weight: 300;
	padding: 0 0 0 8px;
}
.footer-link-container li {
	margin: 4px 0;
}
.disclaimer-container {
	margin: 16px 0 8px 0;
	font-style: italic;
	opacity: 0.6;
	font-weight: 200;
	display: flex;
	flex-direction: column;
}
.contact-icon {
	display: inline-flex;
	align-items: center;
	text-align: center;
}
.contact-icon span {
	margin-right: 4px;
}
.footer-link-container ul .underline-link-center::after {
	height: 2px;
}
/*privacy*/
.privacy-policy {
	padding: 72px 40px 40px 40px;

	background-color: var(--primary);
	color: var(--secondary);
	margin: 0;
}
.privacy-policy .container {
	margin: 0 auto;
	max-width: 1200px;
}
.back-link {
	color: var(--secondary);
	font-size: var(--fs-lg);
	font-weight: 200;
	letter-spacing: 0;
	text-decoration: none;
	border-bottom: 1px solid var(--secondary);
}
/* Loading */
.loading-container {
	display: flex;
	flex-direction: column;
	background-color: var(--accent);
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loading-dot {
	width: 20px;
	height: 20px;
	margin: 8px 0;
	background-color: var(--secondary);
	border-radius: 50%;
	animation: pulsate 1s infinite alternate;
}

@keyframes pulsate {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(2);
	}
}

@media screen and (max-width: 1000px) {
	#landing-page .container {
		padding: 0 40px 80px 40px !important;
	}
	.landing-franchises .container {
		padding: 0 40px 80px 40px !important;
	}
	.franchise-container {
		padding: 0 !important;
	}
	:root {
		font-size: 12px;

		--hero: 15vw;
	}
	.title-under-timer-brand {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		flex-direction: column-reverse;
	}
	.brand {
		margin-bottom: 16px;
	}
	.movie-list-nav {
		max-width: 100%;
		overflow-x: scroll;
	}
	.viewMovies {
		padding: 0 40px 80px 40px !important;
		min-height: 0;
	}
	.landing-movie {
		margin: 20px 0 40px 0;
	}
	.landing-movie-info {
		padding: 0;
		margin: 8px 0;
	}
	.movie-date {
		margin: 0 !important;
	}
	.movie-countdown {
		font-size: var(--fs-xxxl) !important;
	}
	.movie-list-nav::-webkit-scrollbar {
		background-color: gray;
		border-radius: 16px;
		height: 4px;
	}
	.movie-list-nav::-webkit-scrollbar-thumb {
		background: var(--secondary);
	}

	.movie-list-nav ul {
		padding: 4px 0 0 0;
		max-width: 50%;
	}

	.movie-list ul {
		padding: 0;
	}
	.movie-list li {
		font-size: var(--fs-xl);
	}
	.movie-list .movie::after {
		height: 1px;
		opacity: 0.6;
	}
	.trailer-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.ad-trailer-vertical {
		max-width: 728px;
		max-height: 90px;
		background-color: gray;
	}
	.trailer {
		max-width: 100%;
	}
	.trailer-group {
		width: 100%;
	}
	.embedded-trailer {
		max-width: 100%; /* Allow the video container to fill the available width */
		margin-bottom: 20px; /* Add some spacing between trailer and other elements */
	}
	.landing-movie-title {
		font-size: var(--hero) !important;
		-webkit-text-stroke: var(--accent) 2px !important;
	}
}

/*actual landing*/
.landing-wrapper {
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.landing-wrapper video {
	position: absolute;
	top: 0;
	left: 0;
	min-height: 100%;
	min-width: 100%;
	z-index: 1;
}
#landing-page .container {
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
	padding: 0 80px 96px 80px;
}

#landing-page {
	width: 100%;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	z-index: 2;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
#landing-page video {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.8;
	min-width: 100%;
}

.landing-container .content {
	position: relative;
	z-index: 4;
}
.landing-container h1 {
	box-sizing: border-box;
	color: var(--secondary);
	font-size: var(--fs-xxxl);
	margin: 96px 0 0 0;
}

.title-under-timer-brand {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}
.title-under-timer-brand > h2 {
	margin: 0;
	display: inline-block;
}
.landing-container h1 .brand {
	display: inline;
	text-align: center;
	text-transform: uppercase;
}

.landing-movie-title {
	font-size: calc(var(--hero) * 1.3);
	white-space: nowrap;
	-webkit-text-stroke: 4px var(--accent);
	transition: 0.4s all ease;
	height: calc(var(--hero) * 3.3);
	display: flex;
	align-items: center;
	overflow: hidden;
}

.go-to-link {
	color: var(--secondary);
	text-decoration: none;
	transition: 0.4s all ease;
}
.go-to-link:hover span {
	animation: jiggle 0.5s ease; /* Run the animation indefinitely */
}
@keyframes jiggle {
	0% {
		transform: translateX(0); /* Start position */
	}
	25% {
		transform: translateX(4px); /* Move right */
	}
	50% {
		transform: translateX(-4px); /* Move left */
	}
	75% {
		transform: translateX(4px); /* Move right */
	}
	100% {
		transform: translateX(0); /* Back to start position */
	}
}

.landing-container h2 {
	color: var(--secondary);
	font-size: var(--fs-xxxxl);
	font-weight: 500;
	letter-spacing: 1px;
	margin-top: 0;
	transition: all 0.4s ease;
}
.landing-container h3 {
	color: var(--secondary);
	font-size: var(--fs-xxxxl);
	display: inline;
	border-bottom: 2px solid var(--secondary);
	font-family: "Roboto Condensed", sans-serif;
}
.landing-container h3 span {
	font-size: var(--fs-xxl);
	vertical-align: middle;
}
.landing-franchises {
	position: relative;
	overflow: hidden;
	z-index: 2;
	width: 100%;
	transition: 2s all ease;
}
.landing-franchises .container {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 64px 96px 64px;
}

.landing-franchises h2 {
	color: var(--secondary);
	font-size: var(--fs-xxxl);
	text-align: left;
	font-weight: 400;
	margin: 0;
	margin-bottom: 32px;
}
.franchise-container {
	width: 100%;
	z-index: 2;
	padding: 0 16px;
}
.franchise {
	color: var(--secondary);
	font-size: var(--fs-xxxxl);
	font-weight: 300;
	margin: 32px 0;
	letter-spacing: 4px;
	background-color: var(--primary);
	padding: 8px 16px;
	border: 1px solid #343942;
	position: relative;
	text-decoration: none;
	display: block;
	font-family: "Roboto Condensed", sans-serif;
}
.franchise span {
	position: relative;
	z-index: 3;
}
.franchise::before {
	content: attr(data-content);
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: var(--primary);
	transition: all 0.4s ease;
	z-index: 2;
}
.franchise:hover::before {
	width: 100%; /* Slide in from left on hover */
	background-color: var(--accent); /* Your accent color */
}
.franchise:hover {
	cursor: pointer;
}
